export const stage_10 = {
    key: "stage_10",
    name: "레벨10 정면돌파",
    data:
    {
        "width": 12,
        "height": 12,
        "state": [
            {
                "x": 0,
                "y": 0,
                "ty": 0
            },
            {
                "x": 1,
                "y": 0,
                "ty": 0
            },
            {
                "x": 2,
                "y": 0,
                "ty": 0
            },
            {
                "x": 3,
                "y": 0,
                "ty": 0
            },
            {
                "x": 4,
                "y": 0,
                "ty": 0
            },
            {
                "x": 5,
                "y": 0,
                "ty": 0
            },
            {
                "x": 6,
                "y": 0,
                "ty": 0
            },
            {
                "x": 7,
                "y": 0,
                "ty": 0
            },
            {
                "x": 8,
                "y": 0,
                "ty": 0
            },
            {
                "x": 9,
                "y": 0,
                "ty": 0
            },
            {
                "x": 10,
                "y": 0,
                "ty": 0
            },
            {
                "x": 11,
                "y": 0,
                "ty": 0
            },
            {
                "x": 0,
                "y": 1,
                "ty": 0
            },
            {
                "x": 1,
                "y": 1,
                "ty": 0
            },
            {
                "x": 2,
                "y": 1,
                "ty": 0
            },
            null,
            null,
            {
                "x": 5,
                "y": 1,
                "ty": 0
            },
            {
                "x": 6,
                "y": 1,
                "ty": 0
            },
            {
                "x": 7,
                "y": 1,
                "ty": 0
            },
            {
                "x": 8,
                "y": 1,
                "ty": 0
            },
            {
                "x": 9,
                "y": 1,
                "ty": 0
            },
            {
                "x": 10,
                "y": 1,
                "ty": 0
            },
            {
                "x": 11,
                "y": 1,
                "ty": 0
            },
            {
                "x": 0,
                "y": 2,
                "ty": 0
            },
            {
                "x": 1,
                "y": 2,
                "ty": 0
            },
            {
                "x": 2,
                "y": 2,
                "ty": 0
            },
            null,
            null,
            null,
            {
                "x": 6,
                "y": 2,
                "ty": 0
            },
            {
                "x": 7,
                "y": 2,
                "ty": 0
            },
            {
                "x": 8,
                "y": 2,
                "ty": 0
            },
            {
                "x": 9,
                "y": 2,
                "ty": 0
            },
            null,
            {
                "x": 11,
                "y": 2,
                "ty": 0
            },
            {
                "x": 0,
                "y": 3,
                "ty": 0
            },
            {
                "x": 1,
                "y": 3,
                "ty": 0
            },
            {
                "x": 2,
                "y": 3,
                "ty": 0
            },
            null,
            null,
            null,
            {
                "x": 6,
                "y": 3,
                "ty": 0
            },
            {
                "x": 7,
                "y": 3,
                "ty": 0
            },
            {
                "x": 8,
                "y": 3,
                "ty": 0
            },
            {
                "x": 9,
                "y": 3,
                "ty": 0
            },
            null,
            {
                "x": 11,
                "y": 3,
                "ty": 0
            },
            {
                "x": 0,
                "y": 4,
                "ty": 0
            },
            {
                "x": 1,
                "y": 4,
                "ty": 0
            },
            {
                "x": 2,
                "y": 4,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            {
                "x": 7,
                "y": 4,
                "ty": -3
            },
            null,
            null,
            null,
            {
                "x": 11,
                "y": 4,
                "ty": 0
            },
            {
                "x": 0,
                "y": 5,
                "ty": 0
            },
            {
                "x": 1,
                "y": 5,
                "ty": 0
            },
            {
                "x": 2,
                "y": 5,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            {
                "x": 11,
                "y": 5,
                "ty": 0
            },
            {
                "x": 0,
                "y": 6,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            {
                "x": 10,
                "y": 6,
                "ty": -4,
                "mark": true
            },
            {
                "x": 11,
                "y": 6,
                "ty": 0
            },
            {
                "x": 0,
                "y": 7,
                "ty": 0
            },
            {
                "x": 1,
                "y": 7,
                "ty": 0
            },
            {
                "x": 2,
                "y": 7,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            {
                "x": 11,
                "y": 7,
                "ty": 0
            },
            {
                "x": 0,
                "y": 8,
                "ty": 0
            },
            {
                "x": 1,
                "y": 8,
                "ty": 0
            },
            {
                "x": 2,
                "y": 8,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            null,
            {
                "x": 8,
                "y": 8,
                "ty": -3
            },
            null,
            {
                "x": 10,
                "y": 8,
                "ty": -3
            },
            {
                "x": 11,
                "y": 8,
                "ty": 0
            },
            {
                "x": 0,
                "y": 9,
                "ty": 0
            },
            {
                "x": 1,
                "y": 9,
                "ty": 0
            },
            {
                "x": 2,
                "y": 9,
                "ty": 0
            },
            null,
            null,
            null,
            null,
            null,
            {
                "x": 8,
                "y": 9,
                "ty": 0
            },
            null,
            {
                "x": 10,
                "y": 9,
                "ty": 0
            },
            {
                "x": 11,
                "y": 9,
                "ty": 0
            },
            {
                "x": 0,
                "y": 10,
                "ty": 0
            },
            {
                "x": 1,
                "y": 10,
                "ty": 0
            },
            {
                "x": 2,
                "y": 10,
                "ty": 0
            },
            null,
            null,
            null,
            {
                "x": 6,
                "y": 10,
                "ty": 0
            },
            {
                "x": 7,
                "y": 10,
                "ty": 0
            },
            {
                "x": 8,
                "y": 10,
                "ty": 0
            },
            {
                "x": 9,
                "y": 10,
                "ty": 0
            },
            {
                "x": 10,
                "y": 10,
                "ty": 0
            },
            {
                "x": 11,
                "y": 10,
                "ty": 0
            },
            {
                "x": 0,
                "y": 11,
                "ty": 0
            },
            {
                "x": 1,
                "y": 11,
                "ty": 0
            },
            {
                "x": 2,
                "y": 11,
                "ty": 0
            },
            {
                "x": 3,
                "y": 11,
                "ty": 0
            },
            {
                "x": 4,
                "y": 11,
                "ty": 0
            },
            {
                "x": 5,
                "y": 11,
                "ty": 0
            },
            {
                "x": 6,
                "y": 11,
                "ty": 0
            },
            {
                "x": 7,
                "y": 11,
                "ty": 0
            },
            {
                "x": 8,
                "y": 11,
                "ty": 0
            },
            {
                "x": 9,
                "y": 11,
                "ty": 0
            },
            {
                "x": 10,
                "y": 11,
                "ty": 0
            },
            {
                "x": 11,
                "y": 11,
                "ty": 0
            }
        ],
        "entities": [
            {
                "x": 2,
                "y": 6,
                "ty": 1,
                "name": null,
                "life": 3,
                "life_max": 3,
                "damage": 1,
                "damage_dash": 3,
                "dash_gauge": 1,
                "dash_gauge_max": 1,
                "skill_gauge": {
                    "pull": 0,
                    "push": 0,
                    "swap": 0
                },
                "skill_gauge_max": {
                    "pull": 0,
                    "push": 0,
                    "swap": 0
                }
            },
            {
                "x": 4,
                "y": 5,
                "ty": 2,
                "name": "용병",
                "mark": true,
                "life": 5,
                "life_max": 5,
                "damage": 1
            },
            {
                "x": 4,
                "y": 6,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 4,
                "y": 7,
                "ty": 2,
                "name": "용병",
                "mark": true,
                "life": 5,
                "life_max": 5,
                "damage": 1
            },
            {
                "x": 5,
                "y": 5,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 5,
                "y": 7,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 6,
                "y": 6,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 7,
                "y": 6,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 7,
                "y": 5,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 8,
                "y": 5,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 8,
                "y": 7,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 7,
                "y": 7,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 6,
                "y": 8,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            },
            {
                "x": 6,
                "y": 4,
                "ty": 2,
                "name": "경비원",
                "life": 1,
                "life_max": 1,
                "damage": 1
            }
        ],
        "initialTimelimit": 30000,
        "additionalTimePerTick": 10000,
        "starConditions": [
            {
                "star": 3,
                "consumeTimeLessThan": 30000,
                "consumeTurnLessThan": 1,
                "leftLife": 3
            },
            {
                "star": 2,
                "consumeTimeLessThan": 90000,
                "consumeTurnLessThan": 5,
                "leftLife": 2
            },
            {
                "star": 1
            }
        ]
    }

}