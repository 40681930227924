import { stage_1 } from "./stage_1.mjs";
import { stage_10 } from "./stage_10.mjs";
import { stage_11 } from "./stage_11.mjs";
import { stage_12 } from "./stage_12.mjs";
import { stage_2 } from "./stage_2.mjs";
import { stage_3 } from "./stage_3.mjs";
import { stage_4 } from "./stage_4.mjs";
import { stage_5 } from "./stage_5.mjs";
import { stage_6 } from "./stage_6.mjs";
import { stage_7 } from "./stage_7.mjs";
import { stage_8 } from "./stage_8.mjs";
import { stage_9 } from "./stage_9.mjs";

export const Maps = [
    stage_1,
    stage_2,
    stage_3,
    stage_4,
    stage_5,
    stage_6,
    stage_7,
    stage_8,
    stage_9,
    stage_10,
    stage_11,
    stage_12,
]