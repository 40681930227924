import './App.css';

import React, { Fragment } from 'react';

import { Maps } from './Maps/Maps.mjs';

const ADWS_VECTORs = {
    'A': { dirx: -1, diry: 0 },
    'D': { dirx: 1, diry: 0 },
    'W': { dirx: 0, diry: -1 },
    'S': { dirx: 0, diry: 1 },
};

const DASH_VECTORS = {
    'A': {
        'W': { dirx: -2, diry: -1 },
        'S': { dirx: -2, diry: 1 },
    },
    'D': {
        'W': { dirx: 2, diry: -1 },
        'S': { dirx: 2, diry: 1 },
    },
    'W': {
        'A': { dirx: -1, diry: -2 },
        'D': { dirx: 1, diry: -2 },
    },
    'S': {
        'A': { dirx: -1, diry: 2 },
        'D': { dirx: 1, diry: 2 },
    }
};

class Entity {
    constructor(x, y, ty, opts) {
        // TODO: 캐릭터 기본값 모임
        this.x = x;
        this.y = y;
        this.ty = ty;

        if (opts?.ephemeral) {
            return;
        }

        // 목표
        this.mark = opts?.mark;

        if (ty <= 0) {
            return;
        }

        // 기본 공격력
        this.damage = opts?.damage ?? 1;
        // 체력
        this.life = opts?.life;
        this.life_max = opts?.life_max ?? this.life;
        this.name = opts?.name ?? null;

        // 적 이동 관련
        // 현재 남은 이동 틱
        this.cur_move_tick = opts?.move_tick ?? 2;
        // 이동에 필요한 틱
        this.move_tick = this.cur_move_tick;

        // 이동 규칙
        this.move_rule = opts?.move_rule ?? 'follow';
        this.stunned = false;

        // 대시 게이지
        if (ty === 1) {
            this.damage_dash = opts?.damage_dash ?? 3;

            this.dash_gauge = opts?.dash_gauge ?? 1;
            this.dash_gauge_max = opts?.damage_gauge_max ?? 1;

            // 회복/소모량
            this.guage_per_dash = 1;
            this.guage_per_hit = 1;

            this.dash_chain = 0;

            // 스킬 사용가능량
            this.skill_gauge = opts?.skill_gauge ?? {
                'pull': 3,
                'push': 3,
                'swap': 3,
            };

            this.skill_gauge_max = {};
            for (var skillName in this.skill_gauge) {
                if (Object.keys(opts?.skill_gauge_max ?? {}).includes(skillName)) {
                    this.skill_gauge_max[skillName] = opts?.skill_gauge_max[skillName];
                } else {
                    this.skill_gauge_max[skillName] = this.skill_gauge[skillName];
                }
            }
        }
    }

    get is_player() {
        return this.ty === 1;
    }

    get is_enemy() {
        return this.ty > 1 && this.ty < 10;
    }

    get is_character() {
        return this.ty > 0 && this.ty < 10;
    }

    get is_wall() {
        return this.ty === 0;
    }

    get is_empty() {
        return this.ty === -1;
    }

    get dash_cost() {
        return this.dash_chain > 0 ? 0 : this.guage_per_dash;
    }

    get current_dash_damage() {
        if (this.ty !== 1) return 0;

        let damage_remain = this.damage_dash;
        if (this.dash_chain > 2) {
            damage_remain = this.damage_dash * 2
        }
        return damage_remain;
    }

    toJSON() {
        // TODO
        return {
            x: this.x,
            y: this.y,
            ty: this.ty,
            name: this.name,
            mark: this.mark,
            life: this.life,
            life_max: this.life_max,
            damage: this.damage,
            damage_dash: this.damage_dash,
            dash_gauge: this.dash_gauge,
            dash_gauge_max: this.dash_gauge_max,
            skill_gauge: this.skill_gauge,
            skill_gauge_max: this.skill_gauge_max,
        };
    }
}

const DEFAULT_STAR_CONDITION = {
    star: 3,
    consumeTimeLessThan: 10000000000000,
    consumeTurnLessThan: 10000000000000,
    leftLife: 0,
};

class Game {
    constructor(width, height, state, starConditions, initialTimelimit, additionalTimePerTick) {
        this.width = width ?? 8;
        this.height = height ?? 8;

        if (state) {
            this.state = state;
        } else {
            this.state = Array.from({ length: this.width * this.height }).map(() => null);
            this.initialize();
        }

        this.entities = [];

        this.starConditions = starConditions ?? [
            DEFAULT_STAR_CONDITION,
        ]

        this.initialTimelimit = initialTimelimit ?? 5 * 60 * 1000;
        this.additionalTimePerTick = additionalTimePerTick ?? 1 * 60 * 1000;
    }

    initialize() {
        for (let i = 0; i < this.width; i++) {
            this.setTile(i, 0, new Entity(i, 0, 0));
            this.setTile(i, this.height - 1, new Entity(i, this.height - 1, 0));
        }

        for (let i = 0; i < this.height; i++) {
            this.setTile(0, i, new Entity(0, i, 0));
            this.setTile(this.width - 1, i, new Entity(this.width - 1, i, 0));
        }
    }

    set(x, y, ty) {
        console.warn("Game.set is deprecated");
        if (ty === -1) {
            this.setTile(x, y, null);
        } else {
            this.setTile(new Entity(x, y, ty));
        }
    }

    setTile(x, y, entity) {
        const idx = y * this.width + x;
        this.state[idx] = entity;
    }

    get(x, y) {
        console.warn("game.get deprecated");
        if (x < 0 || y < 0 || x >= this.width || y >= this.height) {
            return new Entity(x, y, -1, { ephemeral: true });
        }

        const e = this.entities.find((e) => e.x === x && e.y === y && e.life > 0);
        if (e) {
            return e;
        }

        const idx = y * this.width + x;
        const tileEntity = this.state[idx];
        if (tileEntity) {
            return tileEntity;
        } else {
            return new Entity(x, y, -1, { ephemeral: true });
        }
    }

    getEntity(x, y) {
        if (x < 0 || y < 0 || x >= this.width || y >= this.height) {
            return new Entity(x, y, -1, { ephemeral: true });
        }
        const e = this.entities.find((e) => e.x === x && e.y === y && e.life > 0);
        if (e) {
            return e;
        } else {
            return new Entity(x, y, -1, { ephemeral: true });
        }
    }

    getTile(x, y) {
        if (x < 0 || y < 0 || x >= this.width || y >= this.height) {
            return new Entity(x, y, -1, { ephemeral: true });
        }
        const idx = y * this.width + x;
        const tile = this.state[idx];
        if (tile) {
            return tile;
        } else {
            return new Entity(x, y, -1, { ephemeral: true });
        }
    }

    find(ty) {
        for (let i = 0; i < this.state.length; i++) {
            if (this.state[i].ty === ty) {
                return i;
            }
        }

        return -1;
    }

    // find first object, not empty, with direction
    find_dir(x, y, dirx, diry) {
        while (true) {
            x += dirx;
            y += diry;

            let found = this.get(x, y);
            if (found.ty !== -1) {
                return found;
            }
        }
    }

    move(dst, src) {
        dst.x = src.x;
        dst.y = src.y;

        this.triggerTile(dst);
    }

    triggerTile(entity) {
        if (entity.ty === 1) {
            const tile = this.getTile(entity.x, entity.y);
            if (tile.ty === -2) {
                entity.life = Math.min(entity.life + 1, entity.life_max);
                this.setTile(entity.x, entity.y, null);
            }
            if (tile.ty === -3) {
                entity.dash_gauge = Math.min(entity.dash_gauge + 1, entity.dash_gauge_max);
                this.setTile(entity.x, entity.y, null);
            }
            if (tile.ty === -4) {
                this.setTile(entity.x, entity.y, null);
            }
        }
    }

    swap(dst, src) {
        const { x: tempX, y: tempY } = dst;
        dst.x = src.x;
        dst.y = src.y;

        src.x = tempX;
        src.y = tempY;

        this.triggerTile(dst);
        this.triggerTile(src);
    }

    checkDash(entity, { dirx, diry }) {
        let next1Entity = this.getEntity(entity.x + dirx, entity.y + diry);
        let next1Tile = this.getTile(entity.x + dirx, entity.y + diry);

        const near1Tile = Math.abs(dirx) > Math.abs(diry) ?
            this.getTile(entity.x + dirx - Math.sign(dirx), entity.y + diry) :
            this.getTile(entity.x + dirx, entity.y + diry - Math.sign(diry));
        const near2Tile = this.getTile(entity.x + dirx - Math.sign(dirx), entity.y + diry - Math.sign(diry));


        if (next1Tile.is_wall) {
            return { IsValid: false };
        } else if (near1Tile.is_wall || near2Tile.is_wall) {
            return { IsValid: false };
        } else if (next1Entity.is_enemy && next1Entity.life <= entity.current_dash_damage) {
            return { IsValid: true, target: next1Entity };
        } else if (next1Entity.is_empty) {
            return { IsValid: true, target: next1Entity };
        }
        return { IsValid: false };
    }

    // 대시를 하고, 턴을 이어갈 수 있는 경우 true를 반환
    action(entity, action) {


        let processed = false;

        const hasSkillGauge = Object.keys(entity?.skill_gauge ?? {}).includes(action.ty);

        const player = this.entities[0];

        if (
            hasSkillGauge &&
            entity?.skill_gauge[action.ty] <= 0
        ) {
            return ({ processed });
        }

        if (action.ty === 'move') {
            // 이동 및 일반 공격
            const { dirx, diry } = action;

            const { x, y } = entity;

            const damage = 1;
            let damage_remain = damage;

            const prevEntity = this.getEntity(x + dirx, y + diry);
            const prevTile = this.getTile(x + dirx, y + diry);

            if (prevEntity.is_empty && !prevTile.is_wall) {
                this.move(entity, prevTile);
                processed = true;
            } else if (prevEntity.is_player) {
                // enemies
                const damage_dealt = Math.min(prevEntity.life, damage_remain);
                prevEntity.life -= damage_dealt;
                damage_remain -= damage_dealt;
                if (prevEntity.life === 0) {
                    this.move(entity, prevEntity);
                }
            }
            return { hit: damage_remain !== damage, processed };
        } else if (action.ty === 'dash') {
            // 대시 이동 및 대시 공격
            const { dirx, diry } = action;



            let chain = false;

            let damage_remain = entity.current_dash_damage;

            const { IsValid, target } = this.checkDash(entity, { dirx, diry });

            if (!IsValid) {
                // 장애물. 못갑니다.
                // 중간에 장애물. 못지나갑니다.
            } else if (target.is_enemy) {
                chain = true;
                // next1에 적이 있음. 못죽일 경우. 멈춤
                if (target.life > damage_remain) {

                }
                else {
                    let damage_dealt = Math.min(target.life, damage_remain);

                    target.life -= damage_dealt;
                    damage_remain -= damage_dealt;
                    // 그 뒤에 따라 행동
                    this.move(entity, target);
                    chain = true;
                    processed = true;
                }
            } else if (target.is_empty) {
                this.move(entity, target);
                processed = true;
            }
            return { chain, processed };
        } else if (action.ty === 'pull') {
            // 당기기
            const { dirx, diry } = action;

            let next1Tile = this.getTile(entity.x + dirx, entity.y + diry);
            let next1Entity = this.getEntity(entity.x + dirx, entity.y + diry);
            let next2 = this.getEntity(entity.x + dirx * 2, entity.y + diry * 2);

            if (next1Entity.is_empty && !next1Tile.is_wall && next2.is_enemy) {
                this.move(next2, next1Tile);
                next2.stunned = true;
                processed = true;
            }
        } else if (action.ty == 'push') {
            // 밀어내기
            const { dirx, diry } = action;

            let next1 = this.get(entity.x + dirx, entity.y + diry);
            let next2 = this.getEntity(entity.x + dirx * 2, entity.y + diry * 2);
            let next2Tile = this.getTile(entity.x + dirx * 2, entity.y + diry * 2);

            if (next1.is_enemy && next2.is_empty && !next2Tile.is_wall) {
                const res = this.move(next1, next2);
                next1.stunned = true;
                processed = true;
            }
        } else if (action.ty == 'swap') {
            // 자리바꾸기
            const { dirx, diry } = action;

            let next1 = this.getEntity(entity.x + dirx, entity.y + diry);

            if (next1.is_enemy) {
                const res = this.swap(player, next1);
                next1.stunned = true;
                processed = true;
            }
        } else {
            throw new Error(`unknown action.ty: ${action.ty}`);
        }
        if (hasSkillGauge && processed) {
            player.skill_gauge[action.ty] -= 1;
        }
        return { processed };
    }

    checkWin() {
        const marks = [
            ...this.state.filter((x) => x && x.mark),
            ...this.entities.filter((x) => x.life !== 0 && x.mark),
        ];

        if (marks.length !== 0) {
            return false;
        }
        return true;
    }

}

const CELL_SIZE = 80;
const CELL_MARGIN = 10;
const FONT_SIZE = 12;
const TIMER_INTERVAL = 3;
const colors = [
    'black',
    'red',
    'gray'
];

const entity_tmpls = [
    { ty: 2, life: 1, name: '병사' },
    { ty: 2, life: 5, name: '보스' },
];

const tile_tmpls = [
    { ty: 0, },
    { ty: -2, name: "HP 회복" },
    { ty: -3, name: "아드레날린 회복" },
    { ty: -4, name: "아무것도 안하는 타일" },
];
// TODO
colors[-2] = 'rgba(0, 100, 255, 0.2)';
colors[-3] = 'rgba(100, 0, 255, 0.2)';
colors[-4] = 'rgba(0, 0, 0, 0.2)';

function game_default() {
    const game = new Game(12, 12);

    const me = new Entity(1, 1, 1, { life: 3 });

    game.entities.push(me);

    return game;
}

function game_tc_dash() {
    const game = new Game();

    const me = new Entity(1, 1, 1, { life: 3 });

    me.y = 1;
    game.entities.push(me);

    let y = 1;
    // 뒤가 빔
    game.entities.push(new Entity(2, y, 2));
    y += 1;

    // 뒤가 빔, 막힘
    game.entities.push(new Entity(2, y, 2, { life: 4 }));
    y += 1;

    // 둘다 죽일 수 있음
    game.entities.push(new Entity(2, y, 2));
    game.entities.push(new Entity(3, y, 2));
    y += 1;

    // 두번째 친구 못죽임
    game.entities.push(new Entity(2, y, 2));
    game.entities.push(new Entity(3, y, 2, { life: 3 }));
    y += 1;

    // 두번째 칸, 이어짐
    game.entities.push(new Entity(3, y, 2));
    y += 1;

    // 두번째 칸, 끊어짐
    game.entities.push(new Entity(3, y, 2, { life: 4 }));
    y += 1;

    return game;
}

const presets = {
    'default': game_default,
    'tc_dash': game_tc_dash,
};

const skillModes = [
    // 이동
    1,
    // 당기기
    2,
    // 밀기
    3,
    // 자리바꾸기
    4,
    // 대시
    -1,
];
const skillModeTypes = [
    'move',
    'pull',
    'push',
    'swap',
    'dash',
]
const skillModeNames = [
    "이동",
    "당기기",
    "밀기",
    "자리바꾸기",
    "대시",
];

class App extends React.Component {
    constructor() {
        super();

        this.keyBind = this.onKeyDown.bind(this);
        this.keyUp = this.onKeyUp.bind(this);

        this.canvasRef = React.createRef();
        this.textareaRef = React.createRef();

        this.mouseDown = this.onMouseDown.bind(this, "click");
        this.mouseMove = this.onMouseMove.bind(this, "click");
        this.mouseUp = this.onMouseUp.bind(this, "click");

        this.state = this.initialize();
    }

    initialize(preset) {
        preset = preset ?? this.state?.preset ?? 'default';
        const game = presets[preset]();
        const state = this.initializeWithGame(game);
        state.preset_selected = preset;
        return state;
    }

    initializeWithGame(game) {
        return {
            game,
            preset_selected: null,
            tick: 0,
            step: 0,

            cursor: null,
            pause: false,
            skillMode: 1,
            dashKeyStack: [],

            currentTimelimit: game.initialTimelimit,
            prevTimelimitTime: Date.now(),
            timer_interval: TIMER_INTERVAL,
            timeStack: 0,
        };
    }

    startTimelimitTimer() {
        this.stopTimelimitTimer();
        this.timelimitTimer = setInterval(this.onTimelimit.bind(this), 0);
    }

    stopTimelimitTimer() {
        if (this.timelimitTimer) {
            clearInterval(this.timelimitTimer);
            this.timelimitTimer = null;
        }
    }

    onTimelimit() {
        let { game, pause, step, prevTimelimitTime, currentTimelimit, timeStack } = this.state;
        // 적이 움직일 때 시간 흐름
        if (!pause && step === 0) {
            const deltaTime = Date.now() - prevTimelimitTime;
            currentTimelimit -= deltaTime;
            timeStack += deltaTime;


            if (currentTimelimit < 0) {
                alert("타임 오버");
                this.stopTimelimitTimer();
            }
        }

        this.setState({ prevTimelimitTime: Date.now(), currentTimelimit, timeStack });

    }

    startTickTimer() {
        const { timer_interval } = this.state;
        this.stopTickTimer();
        this.tickTimer = setTimeout(this.onTick.bind(this), timer_interval);
    }

    stopTickTimer() {
        if (this.tickTimer) {
            clearTimeout(this.tickTimer);
            this.tickTimer = null;
        }
    }

    componentDidMount() {
        this.renderCanvas();
        document.addEventListener('keydown', this.keyBind);
        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('mousedown', this.mouseDown);
        document.addEventListener('mousemove', this.mouseMove);
        document.addEventListener('mouseup', this.mouseUp);
        this.startTimelimitTimer();
    }

    componentWillUnmount() {
        this.stopTickTimer();
        document.removeEventListener('keydown', this.keyBind);
        document.removeEventListener('keyup', this.keyUp);
        document.removeEventListener('mousedown', this.mouseDown);
        document.removeEventListener('mousemove', this.mouseMove);
        document.removeEventListener('mouseup', this.mouseUp);
        this.stopTimelimitTimer();
    }

    onTick() {
        let { tick, step, game, currentTimelimit } = this.state;
        const { entities } = game;

        while (step !== 0 && entities[step].life === 0) {
            step = (step + 1) % entities.length;
        }

        if (step === 0) {
            // player turn
            tick += 1;
            const player = game.entities[0];
            const nextTimelimit = Math.min(currentTimelimit + game.additionalTimePerTick, game.initialTimelimit);

            this.setState({ tick, step, currentTimelimit: nextTimelimit }, this.renderCanvas.bind(this));
        } else {
            this.onStep(step);
            step = (step + 1) % entities.length;
            this.setState({ tick, step }, this.renderCanvas.bind(this));
            this.startTickTimer();
        }
    }

    onStep(step) {
        const { game } = this.state;

        const player = game.entities[0];
        const entity = game.entities[step];

        // 스턴 상태 처리
        if (entity.stunned) {
            entity.stunned = false;
            return;
        }

        entity.cur_move_tick -= 1;
        if (entity.cur_move_tick > 0) {
            return;
        } else {
            entity.cur_move_tick = entity.move_tick;
        }

        if (entity.move_rule === 'follow') {
            // 기본 이동 규칙. 플레이어를 따라갑니다.
            //  - 항상 플레이어에게 가까워지는 방향으로 이동합니다.
            //  - 좌우/위아래 거리 차를 줄이는 방향으로 이동합니다.
            //  - 좌우/위아래 거리가 동일하고 좌우/위아래로 모두 이동 가능한 경우, 좌우로 먼저 이동합니다. (tie breaking)

            const dx = player.x - entity.x;
            const dy = player.y - entity.y;

            // x축으로 이동할 수 있는가
            let movex = dx !== 0;
            if (movex) {
                const nextXEntity = game.getEntity(entity.x + Math.sign(dx), entity.y);
                const nextXTile = game.getTile(entity.x + Math.sign(dx), entity.y);
                if (nextXEntity.is_enemy || nextXTile.is_wall) {
                    movex = false;
                }
            }
            // y축으로 이동할 수 있는가
            let movey = dy !== 0;
            if (movey) {
                const nextYEntity = game.getEntity(entity.x, entity.y + Math.sign(dy));
                const nextYTile = game.getTile(entity.x, entity.y + Math.sign(dy));
                if (nextYEntity.is_enemy || nextYTile.is_wall) {
                    movey = false;
                }
            }

            let movetox = movex;
            // y축으로도 이동할 수 있고, y축 거리가 더 먼 경우 y축으로 이동합니다.
            if (movey && Math.abs(dy) > Math.abs(dx)) {
                movetox = false;
            }

            if (movetox) {
                game.action(entity, { ty: 'move', dirx: Math.sign(dx), diry: 0 });
            } else if (movey) {
                game.action(entity, { ty: 'move', dirx: 0, diry: Math.sign(dy) });
            } else {
                // 움직이지 않습니다
            }
        }
    }

    canvasCursor(type, e) {
        const { game } = this.state;
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        if (type === "touch") {
            e = e.touches[0];
        }

        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;

        const viewWidth = game.width * CELL_SIZE;
        const viewHeight = game.height * CELL_SIZE;

        if (x < 0 || y < 0 || x > viewWidth || y > viewHeight) {
            return null;
        }

        return { x, y };
    }

    onMouseDown(type, ev) {
    }

    onMouseUp(type, ev) {
    }

    onMouseMove(type, ev) {
        const cursor = this.canvasCursor(type, ev);
        this.setState({ cursor }, this.renderCanvas.bind(this));
    }

    /**
     * 
     * @param {KeyboardEvent} ev 
     * @returns 
     */
    onKeyDown(ev) {
        let { step, game, cursor, pause, skillMode, dashKeyStack, timeStack, MapSelected } = this.state;

        const me = game.entities[0];
        let updated = false;

        function move({ dirx, diry }) {
            // 내 턴이 아닌 경우, 무시합니다
            if (step !== 0) {
                return;
            }

            updated = true;
            const ty = 'move';

            const res = game.action(me, { ty, dirx, diry });

            me.dash_chain = 0;

            // 플레이어의 턴이 종료됩니다.
            if (res.processed && !ev.altKey) {
                // meta 키를 누르면 디버깅용으로 턴 소모 없이 이동 가능
                step += 1;
            }
        }


        function handleMove() {
            // 상하좌우 입력
            if (ev.code === 'KeyA') {
                move({ dirx: -1, diry: 0 });
                return true;
            }
            if (ev.code === 'KeyD') {
                move({ dirx: 1, diry: 0 });
                return true;
            }
            if (ev.code === 'KeyW') {
                move({ dirx: 0, diry: -1 });
                return true;
            }
            if (ev.code === 'KeyS') {
                move({ dirx: 0, diry: 1 });
                return true;
            }
            return false;
        }

        function pull({ dirx, diry }) {
            // 내 턴이 아닌 경우, 무시합니다
            if (step !== 0) {
                return;
            }

            updated = true;
            const ty = 'pull';

            const res = game.action(me, { ty, dirx, diry });
        }

        function handlePull() {
            // 상하좌우 입력
            if (ev.code === 'KeyA') {
                return pull({ dirx: -1, diry: 0 });
            }
            if (ev.code === 'KeyD') {
                return pull({ dirx: 1, diry: 0 });
            }
            if (ev.code === 'KeyW') {
                return pull({ dirx: 0, diry: -1 });
            }
            if (ev.code === 'KeyS') {
                return pull({ dirx: 0, diry: 1 });
            }
            return false;
        }

        function push({ dirx, diry }) {
            // 내 턴이 아닌 경우, 무시합니다
            if (step !== 0) {
                return;
            }

            updated = true;
            const ty = 'push';

            const res = game.action(me, { ty, dirx, diry });
        }

        function handlePush() {
            // 상하좌우 입력
            if (ev.code === 'KeyA') {
                return push({ dirx: -1, diry: 0 });
            }
            if (ev.code === 'KeyD') {
                return push({ dirx: 1, diry: 0 });
            }
            if (ev.code === 'KeyW') {
                return push({ dirx: 0, diry: -1 });
            }
            if (ev.code === 'KeyS') {
                return push({ dirx: 0, diry: 1 });
            }
            return false;
        }

        function swap({ dirx, diry }) {
            // 내 턴이 아닌 경우, 무시합니다
            if (step !== 0) {
                return;
            }

            updated = true;
            const ty = 'swap';

            const res = game.action(me, { ty, dirx, diry });
        }

        function handleSwap() {
            // 상하좌우 입력
            if (ev.code === 'KeyA') {
                return swap({ dirx: -1, diry: 0 });
            }
            if (ev.code === 'KeyD') {
                return swap({ dirx: 1, diry: 0 });
            }
            if (ev.code === 'KeyW') {
                return swap({ dirx: 0, diry: -1 });
            }
            if (ev.code === 'KeyS') {
                return swap({ dirx: 0, diry: 1 });
            }
            return false;
        }

        function handleDash() {
            const key = ev.code.replace("Key", "");
            if (dashKeyStack.length === 1 && dashKeyStack.includes(key) === false) {
                if (Object.keys(DASH_VECTORS[dashKeyStack[0]]).includes(key)) {
                    dashKeyStack.push(key);
                    dash(DASH_VECTORS[dashKeyStack[0]][dashKeyStack[1]]);
                    return true;
                }
            } else if (dashKeyStack.length === 0) {
                if (Object.keys(DASH_VECTORS).includes(key)) {
                    dashKeyStack.push(key);
                    return true;
                }
            }
            return false;
        }

        function dash({ dirx, diry }) {
            const ty = 'dash';

            const dash_cost = me.dash_cost;

            // 게이지가 충분하지 않은 경우, 대시할 수 없습니다.
            if (me.dash_gauge < dash_cost) {
                return;
            }

            const res = game.action(me, { ty, dirx, diry });
            if (res.processed) {
                // 대시 게이지를 소모합니다.
                me.dash_gauge -= dash_cost;

                if (res.chain) {
                    // 대시 체인.
                    me.dash_chain += 1;
                } else {
                    me.dash_chain = 0;
                    if (res.hit) {
                        // 공격 성공. 대시 게이지를 회복합니다.
                        me.dash_gauge = Math.min(me.guage_per_hit + me.dash_gauge, me.dash_gauge_max);
                    }
                }
            }
        }

        if (pause) {
            let rerender = false;

            if (cursor) {
                let { x, y } = cursor;
                x = Math.floor(x / CELL_SIZE);
                y = Math.floor(y / CELL_SIZE);

                // 지우기
                if (ev.key === 'x') {
                    game.entities = game.entities.filter((e) => !(e.x === x && e.y === y));
                    rerender = true;
                }

                // 만들기
                if (ev.key === 'c') {
                    const foundIndex = game.entities.findIndex((e) => e.x === x && e.y === y);
                    const found = game.entities[foundIndex];

                    // TODO: 순서?
                    if (!found) {
                        const tmpl = entity_tmpls[0];
                        // 없는 경우 만들기
                        const entity = new Entity(x, y, tmpl.ty, tmpl);
                        game.entities.push(entity)
                    } else {
                        // 종류를 바꾸기
                        let idx = entity_tmpls.findIndex((e) => e.name === found.name);
                        idx = (idx + 1) % entity_tmpls.length;

                        const tmpl = entity_tmpls[idx];

                        const entity = new Entity(x, y, tmpl.ty, tmpl);
                        game.entities[foundIndex] = entity;
                    }
                    rerender = true;
                }

                // 타일 만들기
                if (ev.key === 'b') {
                    const tile = game.getTile(x, y);
                    if (tile.is_empty) {
                        const { ty, ...opts } = tile_tmpls[0]
                        game.setTile(x, y, new Entity(x, y, ty, opts));
                    }
                    else {
                        let idx = tile_tmpls.findIndex((e) => e.ty === tile.ty);
                        idx = (idx + 1) % tile_tmpls.length;

                        const { ty, ...opts } = tile_tmpls[idx];

                        game.setTile(x, y, new Entity(x, y, ty, opts));
                    }
                    rerender = true;
                }
                // 타일 지우기
                if (ev.key === 'v') {
                    game.setTile(x, y, null);
                    rerender = true;
                }
                // 엔티티를 마크 토글
                if (ev.key === 'n') {
                    const entity = game.getEntity(x, y);
                    if (!entity.is_empty) {
                        entity.mark = !entity.mark;
                        rerender = true;
                    }
                }
                // 타일을 마크 토글
                if (ev.key === 'm') {
                    const tile = game.getTile(x, y);
                    if (!tile.is_empty) {
                        tile.mark = !tile.mark;
                        rerender = true;
                    }
                }
            }

            if (handleMove()) {
                rerender = true;
            }

            if (rerender) {
                this.setState({ game }, this.renderCanvas.bind(this));
                return;
            }
        }

        if (!pause) {
            // 초기화
            if (ev.key === 'r') {
                this.stopTickTimer();

                const index = Maps.findIndex(x => x.key === MapSelected);
                if (index !== -1) {
                    const { data } = Maps[index];
                    this.LoadFromJSON(JSON.parse(JSON.stringify(data)));
                } else {
                    this.setState(this.initialize(), this.renderCanvas.bind(this));
                }
                return;
            }

            this.changeSkillMode(ev);

            if (skillMode === 1) {
                if (me.dash_gauge >= me.dash_cost && ev.code.includes('Shift')) {
                    this.setState({ skillMode: -1 }, this.renderCanvas.bind(this));
                    handleDash();
                    updated = true;
                }
                else {
                    handleMove();
                }
            }
            else if (skillMode === -1) {
                if (handleDash()) {
                    updated = true;
                    this.setState({ game, dashKeyStack }, this.renderCanvas.bind(this));
                    if (me.dash_gauge < me.dash_cost) {
                        this.setState({ skillMode: 1, dashKeyStack: [] }, this.renderCanvas.bind(this));
                    }
                }

            } else if (skillMode === 2) {
                if (handlePull()) {
                    updated = true;
                    this.setState({ game }, this.renderCanvas.bind(this));
                }
            } else if (skillMode === 3) {
                if (handlePush()) {
                    updated = true;
                    this.setState({ game }, this.renderCanvas.bind(this));
                }
            } else if (skillMode === 4) {
                if (handleSwap()) {
                    updated = true;
                    this.setState({ game }, this.renderCanvas.bind(this));
                }
            }

            if (updated) {
                this.renderCanvas();
                // TODO
                // renderCanvas 이후 호출할 목적이었는데 안먹음
                this.setState({}, () => {
                    if (game.checkWin()) {
                        this.showWin();
                    }
                });

                this.setState({ step });

                if (step > 0) {
                    this.startTickTimer();
                }
            }
        }
    }

    /**
     * 
     * @param {KeyboardEvent} ev 
     */
    onKeyUp(ev) {
        const { game, skillMode, dashKeyStack } = this.state;
        if (skillMode === -1) {
            if (ev.code.includes('Shift')) {
                this.setState({ skillMode: 1, dashKeyStack: [] }, this.renderCanvas.bind(this));
                return;
            }
            else {
                const code = ev.code.replace("Key", "");
                if (dashKeyStack.includes(code)) {
                    dashKeyStack.splice(dashKeyStack.indexOf(code));
                    this.setState({ dashKeyStack }, this.renderCanvas.bind(this));
                    return;
                }
            }
        }
    }

    changeSkillMode(ev) {
        const { skillMode } = this.state;

        // 이동
        if (ev.code === 'Digit1') {
            this.setState({ skillMode: 1 }, this.renderCanvas);
            return true;
        }
        // 끌어오기
        if (ev.code === 'Digit2') {
            this.setState({ skillMode: 2 }, this.renderCanvas);
            return true;
        }
        // 밀기
        if (ev.code === 'Digit3') {
            this.setState({ skillMode: 3 }, this.renderCanvas);
            return true;
        }
        // 자리바꾸기
        if (ev.code === 'Digit4') {
            this.setState({ skillMode: 4 }, this.renderCanvas);
            return true;
        }
        return false;
    }

    renderCanvas() {
        const { game, cursor, skillMode, dashKeyStack } = this.state;

        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        const renderScale = window.devicePixelRatio;

        ctx.resetTransform();
        ctx.scale(renderScale, renderScale);

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, game.width * CELL_SIZE, game.height * CELL_SIZE);
        ctx.font = `bold ${FONT_SIZE}px nine`;

        ctx.beginPath();
        for (let i = 0; i < game.height; i++) {
            const x0 = 0;
            const x1 = game.width * CELL_SIZE;
            const y = i * CELL_SIZE;

            ctx.moveTo(x0, y);
            ctx.lineTo(x1, y);

        }
        for (let i = 0; i < game.width; i++) {
            const y0 = 0;
            const y1 = game.height * CELL_SIZE;
            const x = i * CELL_SIZE;

            ctx.moveTo(x, y0);
            ctx.lineTo(x, y1);
        }
        ctx.stroke();

        function renderEntity(entity) {
            const { x, y, ty } = entity;

            const xx = x * CELL_SIZE;
            const yy = y * CELL_SIZE;
            let color = colors[ty];
            if (entity.ty === 1 && entity.dash_chain > 2) {

                color = 'pink'
            }

            let cell_margin = 0;
            if (ty > 0) {
                cell_margin = CELL_MARGIN;
            }
            let cell_size = CELL_SIZE - cell_margin * 2;

            ctx.fillStyle = color;
            ctx.fillRect(xx + cell_margin, yy + cell_margin, cell_size, cell_size);

            let top = yy + CELL_MARGIN;
            let left = xx;

            if (entity.life) {
                top += CELL_MARGIN;
                left += 10;

                ctx.fillStyle = 'black';

                if (entity.name) {
                    ctx.fillText(`${entity.name}`, left, top);
                    top += FONT_SIZE;
                }

                ctx.fillText(`life=${entity.life}`, left, top);
                top += FONT_SIZE;

                if (entity.is_enemy) {
                    let text = '';
                    // 적의 경우
                    if (entity.cur_move_tick > 1) {
                        text = `${entity.cur_move_tick}턴 뒤 이동`;
                    } else {
                        text = `오는 턴 이동`;
                    }

                    ctx.fillText(text, left, top);
                    top += FONT_SIZE;

                    if (entity.stunned) {
                        ctx.fillText(`기절`, left, top);
                    }

                } else if (entity.is_player) {
                    ctx.fillText(`게이지=${entity.dash_gauge}/${entity.dash_gauge_max}`, left, top);
                    top += FONT_SIZE;

                    if (entity.dash_chain > 0) {
                        ctx.fillText(`대시 체인=${entity.dash_chain}`, left, top);
                        top += FONT_SIZE;
                    }

                    const dash_cost = entity.dash_cost;
                    if (entity.dash_gauge >= dash_cost) {
                        ctx.fillText(`대시 가능`, left, top);
                        top += FONT_SIZE;
                    }
                }
            }
            if (entity.mark) {
                ctx.fillStyle = 'black';
                ctx.fillText(`X`, left, top);
                top += FONT_SIZE;
            }
        }

        function renderPullArea(entity) {
            for (let vector of Object.values(ADWS_VECTORs)) {
                const { dirx, diry } = vector;

                // 여기 중복코드 될 것 같음
                let next1Tile = game.getTile(entity.x + dirx, entity.y + diry);
                let next1Entity = game.getEntity(entity.x + dirx, entity.y + diry);
                let next2 = game.getEntity(entity.x + dirx * 2, entity.y + diry * 2);

                if (next1Entity.is_empty && !next1Tile.is_wall && next2.is_enemy) {
                    ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
                    ctx.fillRect(next2.x * CELL_SIZE, next2.y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
                }
                // 여기 중복 코드 될 것 같음
            }
        }

        function renderPushArea(entity) {

            for (let vector of Object.values(ADWS_VECTORs)) {
                const { dirx, diry } = vector;

                // 여기 중복코드 될 것 같음
                let next1 = game.get(entity.x + dirx, entity.y + diry);
                let next2 = game.getEntity(entity.x + dirx * 2, entity.y + diry * 2);
                let next2Tile = game.getTile(entity.x + dirx * 2, entity.y + diry * 2);

                if (next1.is_enemy && next2.is_empty && !next2Tile.is_wall) {
                    ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
                    ctx.fillRect(next1.x * CELL_SIZE, next1.y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
                }
            }
        }

        function renderSwapArea(entity) {

            for (let vector of Object.values(ADWS_VECTORs)) {
                const { dirx, diry } = vector;

                // 여기 중복코드 될 것 같음
                let player = game.entities[0];
                let next1 = game.getEntity(entity.x + dirx, entity.y + diry);

                if (next1.is_enemy) {
                    ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
                    ctx.fillRect(next1.x * CELL_SIZE, next1.y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
                }

            }
        }

        function renderDashArea(entity) {
            if (entity.dash_gauge < entity.dash_cost) {
                return;
            }

            var firstDirections = dashKeyStack.length === 0 ?
                DASH_VECTORS :
                {
                    [dashKeyStack[0]]: DASH_VECTORS[dashKeyStack[0]]
                };
            for (let secondKey in firstDirections) {
                const secondDirections = dashKeyStack.length === 2 ?
                    {
                        [dashKeyStack[1]]: DASH_VECTORS[dashKeyStack[0]][[dashKeyStack[1]]]
                    }
                    : firstDirections[secondKey]
                for (let dstKey in secondDirections) {
                    const { dirx, diry } = firstDirections[secondKey][dstKey];
                    let next1 = game.get(entity.x + dirx, entity.y + diry);
                    const { IsValid } = game.checkDash(entity, { dirx, diry });
                    if (IsValid) {
                        ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
                        ctx.fillRect(next1.x * CELL_SIZE, next1.y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
                    }
                }
            }
        }

        for (let y = 0; y < game.height; y++) {
            for (let x = 0; x < game.width; x++) {
                const tile = game.getTile(x, y);
                if (!tile.is_empty) {
                    renderEntity(tile);
                }
                const entity = game.getEntity(x, y);
                if (!entity.is_empty) {
                    renderEntity(entity);
                }
            }
        }

        if (cursor) {
            let { x, y } = cursor;
            x = Math.floor(x / CELL_SIZE);
            y = Math.floor(y / CELL_SIZE);

            ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
            ctx.fillRect(x * CELL_SIZE, y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
        }

        if (skillMode === 2) {
            renderPullArea(game.entities[0]);
        }
        if (skillMode === 3) {
            renderPushArea(game.entities[0]);
        }
        if (skillMode === 4) {
            renderSwapArea(game.entities[0]);
        }
        if (skillMode === -1) {
            renderDashArea(game.entities[0]);
        }

        /*
        for (const entity of game.entities) {
            renderEntity(entity);
        }
        */
    }

    onSave() {
        const area = this.textareaRef.current;
        const { game } = this.state;

        const savedata = {
            width: game.width,
            height: game.height,
            state: game.state,
            entities: game.entities,
            initialTimelimit: game.initialTimelimit,
            additionalTimePerTick: game.additionalTimePerTick,
            starConditions: game.starConditions,
        };

        area.value = JSON.stringify(savedata, null, 2);
    }

    onLoad() {
        const area = this.textareaRef.current;
        const value = area.value;
        console.log(value);
        const json = JSON.parse(value);

        this.LoadFromJSON(json);
    }

    LoadFromJSON(json) {
        const { width, height, entities, state: stateJSON, starConditions, initialTimelimit, additionalTimePerTick } = json;
        const state = stateJSON.map((item) => {
            if (item === null) return null;
            const { x, y, ty, ...opts } = item;
            return new Entity(x, y, ty, opts);
        });

        const game = new Game(width, height, state, starConditions, initialTimelimit, additionalTimePerTick);
        game.entities = entities.map(({ x, y, ty, ...opts }) => {
            return new Entity(x, y, ty, opts);
        });

        this.setState(this.initializeWithGame(game), this.renderCanvas.bind(this));
    }

    setPause(pause) {
        this.setState({ pause });
    }

    msToMS(ms) {
        let seconds = ms / 1000;
        const minutes = parseInt(seconds / 60);
        seconds = seconds % 60;
        return { minutes, seconds, text: `${minutes.toString().padStart(2, '0')}:${Math.floor(seconds).toFixed(0).padStart(2, '0')}` };
    }

    showWin() {
        const { timeStack, tick, step, game: { starConditions, entities: [player, ...enemies] } } = this.state;

        const consumeTurn = tick + Math.ceil(step);

        let text = "목표 완료\n";
        text += `소요 시간: ${this.msToMS(timeStack).text}\n`;
        text += `소요 턴: ${consumeTurn}\n`;
        text += `남은 체력: ${player.life}\n`;

        let selectedStarCondition = starConditions[starConditions.length - 1];
        for (let i = 0; i < starConditions.length; i++) {
            const starCondition = starConditions[i];
            if (
                Object.keys(starCondition).includes('consumeTimeLessThan') &&
                starCondition.consumeTimeLessThan < timeStack
            ) continue;
            if (
                Object.keys(starCondition).includes('consumeTurnLessThan') &&
                starCondition.consumeTurnLessThan < consumeTurn
            ) continue;
            if (
                Object.keys(starCondition).includes('leftLife') &&
                starCondition.leftLife > player.life
            ) continue;
            selectedStarCondition = starCondition;
            break;
        }
        text += `별: ${selectedStarCondition.star}`;
        alert(text);
    }

    render() {
        const { tick, step, game, pause, skillMode, currentTimelimit, timeStack, timer_interval, MapSelected } = this.state;

        const { width, height } = game;
        const player = game.entities[0];

        const renderScale = window.devicePixelRatio;
        const mult = renderScale * CELL_SIZE;

        const preset_buttons = Object.keys(presets).map((p) => {
            return <button key={p} onClick={() => {
                this.setState(this.initialize(p), this.renderCanvas.bind(this));
            }}>{p}</button>;
        });

        let btn = <button onClick={() => this.setPause(true)}>Pause</button>;
        if (pause) {
            btn = <button onClick={() => this.setPause(false)}>Play</button>;
        }

        const skillModeIndicators = skillModes.map((mode, index) => {
            const ty = skillModeTypes[index];
            const hasGaugeMax = Object.keys(player?.skill_gauge ?? {}).includes(ty);

            return (
                <Fragment key={mode}>
                    <input type="radio" id={mode} name="skillMode" value={mode} checked={skillMode === mode} disabled />
                    <label>
                        {skillModeNames[index]}
                        {hasGaugeMax ? (
                            `(${player.skill_gauge[ty]}/${player.skill_gauge_max[ty]})`
                        ) : ''}
                    </label>
                </Fragment>
            )
        });

        const MapsFragment = (<div>
            <select value={MapSelected} onChange={(e) => {
                const key = e.target.value;

                this.setState({ MapSelected: key });
            }}>
                <option value=""></option>
                {Maps.map(({ key, name, data }) =>
                    <option key={key} value={key}>{name}</option>
                )}
            </select>
            <button onClick={() => {
                const index = Maps.findIndex(x => x.key === MapSelected);
                if (index === -1) return;

                const { data } = Maps[index];
                this.LoadFromJSON(JSON.parse(JSON.stringify(data)));
            }}>불러오기</button>
        </div>);

        return (
            <div>
                <p>
                    playmode: WASD (일반 이동), Shift+WS/Shift+AD (대쉬-1차 방향 지정),Shift+WS+AD/Shift+AD+WS (대시 공격) R (재시작)<br />
                    editmode: WASD (내 캐릭터 이동), C (적 생성하기/종류 바꾸기), X (적 지우기), B (타일 생성/종류 바꾸기), V (타일 지우기), N (적을 목표로 지정/해제), M (타일을 목표로 지정/해제)
                </p>
                <div>
                    <button onClick={this.onSave.bind(this)}>save</button>
                    <button onClick={this.onLoad.bind(this)}>load</button>
                    <textarea ref={this.textareaRef}>
                    </textarea>
                </div>
                {btn}
                {preset_buttons}
                {MapsFragment}
                <p>
                    tick={tick},
                    step={step},
                    currentTimelimit={this.msToMS(currentTimelimit).text},
                    timeStack={this.msToMS(timeStack).text},
                    timer_interval=<input type={'number'} value={timer_interval} onChange={(e) => {
                        this.setState({ timer_interval: e.target.value });
                    }} />
                </p>
                <div>
                    {skillModeIndicators}
                </div>
                <canvas id="canvas" ref={this.canvasRef}
                    style={{ width: width * CELL_SIZE, height: height * CELL_SIZE }}
                    width={width * mult}
                    height={height * mult} />
            </div>
        );
    }
}

export default App;
